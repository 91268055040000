<template>
  <div>

    <b-form-row>
      <b-col>
        <check-box-list-control v-model="schedule.itemTypes" id-field="itemTypeId" :options="itemTypesSelectOptions" name="itemTypeIds" label="What items do you think you will be donating?" required />
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <radio-button-list-control v-model="schedule.sizeTypeId" :options="sizeTypesSelectOptions" name="sizeTypeId" label="About how many boxes or bags will you be donating?" required />
      </b-col>
    </b-form-row>

    <p v-if="publicNotes">{{publicNotes}}</p>

  </div>
</template>

<script>
import FormMixin from '../mixins/FormMixin.vue';

export default {
  name: 'DonatedItemsControl',
  mixins: [FormMixin],
  props: {
    schedule: null,
    itemTypes: null,
    sizeTypes : null,
    publicNotes: null
  },
  data() {
    return {
    };
  },
  computed: {
    itemTypesSelectOptions: function () {
      if (!this.itemTypes)
        return null;

      return this.itemTypes.map(x => ({ text: x.name, value: x.id }));
    },
    sizeTypesSelectOptions: function () {
      if (!this.sizeTypes)
        return null;

      return this.sizeTypes
      .filter(x => x.name !== '15+') // Remove items where the name is '15+'
      .map(x => ({ text: x.name, value: x.id }));
    }
  },
  methods: {
    isValid: function () {
      return this.isFormValid();
    }
  },
  mounted () {
  }  
}
</script>
