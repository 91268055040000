import axios from "../apis/api.js";
import ApiEndpoints from "../apis/apiEndpoints.js";
import { catched } from "./global";


const ZipcodeAPI = {
  get: async () => {
        return "";
  },

};
export default ZipcodeAPI;
