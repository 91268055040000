<template>
  <div>
    <b-form-row v-if="!addressOnly">
      <b-col>
        <text-control v-model="schedule.donor.firstName" placeholder="First Name" required :disabled="isRouted" />
      </b-col>
      <b-col>
        <text-control v-model="schedule.donor.lastName" placeholder="Last Name" required :disabled="isRouted" />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <text-control v-model="schedule.addressLine1" placeholder="Address Line 1" required :disabled="isRouted" />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <text-control v-model="schedule.addressLine2" placeholder="Address Line 2" :disabled="isRouted" />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <text-control v-model="schedule.city" placeholder="City" required :disabled="isRouted" />
      </b-col>
      <b-col>
        <select-list-control v-model="schedule.state" :options="stateOptions" required :disabled="isRouted" />
      </b-col>
      <b-col>
        <text-control v-model="schedule.zipCode" placeholder="Zip Code" required :disabled="isRouted || disableZipCode" />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <checkbox-control label="Please check  if address is a School, Business, Church, or Storage Unit" v-model="schedule.isSpecialPickup" />
      </b-col>
    </b-form-row>
    <b-form-row v-if="!addressOnly">
      <b-col>
        <text-control v-model="schedule.emailAddress" placeholder="Email Address" required type="email" />
      </b-col>
    </b-form-row>
    <b-form-row v-if="!addressOnly">
      <b-col>
        <text-control v-model="schedule.phoneNumber" :placeholder="showSmsWarning ? 'Cell Phone Number' : 'Phone Number'" min-length="10" max-length="10" required type="tel" template="phone" />
        <b-alert variant="success" :show="showSmsWarning">
          When you click continue, we'll send an SMS text message to your phone number with a six-digit verification code. You'll need to enter this code on the next page to continue.
        </b-alert>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import Constants from '../../store/constants'
import FormMixin from '../mixins/FormMixin.vue';

export default {
  name: 'YourInfoControl',
  mixins: [FormMixin],
  props: {
    schedule: {
      default: null
    },
    addressOnly: {
      default: false
    },
    disableZipCode: {
      default: true
    },
    showSmsWarning: {
      default: false
    }
  },
  data() {
    return {
      stateOptions: Constants.stateOptions
    };
  },
  computed: {
    isRouted: function () {
      return this.schedule && this.schedule.scheduleCutoff && this.schedule.scheduleCutoff.state != 'Open';
    }
  },
  methods: {
    isValid: function () {
      return this.isFormValid();
    }
  },
  mounted () {
  }  
}
</script>
